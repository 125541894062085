<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 text-end">
        <v-btn-toggle v-model="toggle_view" mandatory color="primary">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" value="list">
                <v-icon>list</v-icon>
              </v-btn>
            </template>
            <span>List View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" value="map">
                <v-icon>map</v-icon>
              </v-btn>
            </template>
            <span>Map View</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row no-gutters v-show="toggle_view == 'map'">
      <v-col cols="12">
        <google-map-view></google-map-view>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" v-show="toggle_view == 'list'">
        <zones-list-view></zones-list-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";
import GoogleMapView from '@/components/zones/GoogleMapView.vue';
import ZonesListView from '@/components/zones/ZonesListView.vue';

export default {
  name: "ZonesView",
  components: {GoogleMapView, ZonesListView},
  data() {
    return {
      toggle_view: 'list',
      zoom: 2,
      markers: [],
      selectedMarker: {},

      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      locInfoWinPos: {
        lat: 0,
        lng: 0,
      },
      locInfoWinOpen: true,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0,
        },
      },
      locInfoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      zones: "pinaStore/getterZones",
      selectedZone: "pinaStore/getterSelectedZone",
    }),
    center() {
      return this.zones.length > 0
        ? { lat: Number(this.zones[0].lat), lng: Number(this.zones[0].lng) }
        : { lat: 0, lng: 0 };
    },
  },
  watch: {
    selectedZone(newValue) {
      this.zoneSelected = newValue;
    },
  },
  methods: {
    // onZoneChange(zid) {
    //   const zoneObj = this.$store.getters["pinaStore/getterZoneById"](zid);
    //   this.$store.commit("pinaStore/SET_SELECTED_ZONE", zoneObj);
    //   EventBus.$emit("zone-changed");
    //   console.log("edmited zne change")
    //   this.infoWinOpen = false;
    //   this.$router.push({ path: "/dashboard" });
    // },
    onZoneClick(m) {
      this.selectedMarker = Object.assign({}, this.selectedMarker, m);
      this.infoWindowPos = {
        lat: Number(this.selectedMarker.lat),
        lng: Number(this.selectedMarker.lng),
      };
      this.infoWinOpen = !this.infoWinOpen;
    },
  },
};
</script>
