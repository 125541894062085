<template>
  <v-card flat>
    <v-card-title>
      <v-container fluid pa-0>
        <v-row no-gutters class="justify-end">
          <v-col class="align-self-center">
            <span>Zones</span>
          </v-col>
          <v-col cols="3" class="text-end">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              hide-details
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="zoneHeaders"
        :items="zones"
        :search="search"
        class="pt-2"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                class="mr-2"
                color="primary"
                x-small
                @click="navigateToEditZone(item.zid)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span> Edit Zone</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="primary"
                x-small
                @click="changeImage()"
              >
                <v-icon>insert_photo</v-icon>
              </v-btn>
            </template>
            <span>Change Image</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="changeImageDialog" max-width="600">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Change Zone Image</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col cols="12">
                <v-file-input
                v-model="uploadedImage"
                  label="browse Image"
                  accept="image/png, image/jpeg, image/bmp"
                  outlined
                  prepend-icon="insert_photo"
                  @change="onUploadedImageChange()"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-img :src="url"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="changeImageDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            @click="uploadImage()"
            >Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import * as API from "@/api/api";
import { mapGetters } from "vuex";
// import axios from 'axios';
export default {
  name: "ZonesListView",
  data() {
    return {
      changeImageDialog: false,
      zone: {
        zoneName: [],
        email: "",
        acntType: [],
        password: "",
      },
      url:null,
      uploadedImage:null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
      search: "",
      zoneHeaders: [
        {
          text: "Name",
          align: "left",
          filterable: false,
          value: "name",
        },
        { text: "Short Name", value: "short_name", align: "left" },
        { text: "Time Zone", value: "timezone", align: "left" },
        { text: "Address", value: "address", align: "left" },
        { text: "Actions", value: "actions", align: "center", width: "5%" },
      ],
      addAccountDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      zones: "pinaStore/getterZones",
    }),
  },
  watch: {},
  methods: {
    navigateToEditZone(zid) {
      this.$router.push({ path: "/edit/zone/" + zid });
      // this.addAccountDialog = true;
    },
    onUploadedImageChange(){
       this.url= this.uploadedImage ? URL.createObjectURL(this.uploadedImage) : ""
    },
    changeImage() {
      this.changeImageDialog = true;
      this.uploadedImage = null;
      this.url = null;
    },
    uploadImage(){
      // let postData = {
      //   divrt:"d6f15ec3c0c053d0e34c2a82175e322ce887f7d2201f7f0bb5a20ef732561b3f",
      //   zid:"3",
      //   soruce:"1",
      //   pic:this.url
      // }
      // let result = axios.post('https://meghak2.divrt.co/api/v1/uploadPic2', postData)
      // console.log(result)
//        'pic=@"/home/meghak/Downloads/61947.jpg"' \
// --form 'divrt="d6f15ec3c0c053d0e34c2a82175e322ce887f7d2201f7f0bb5a20ef732561b3f"' \
// --form 'zid="3"' \
// --form 'source="1"'
    }
  },
};
</script>
<style lang="sass">
</style>