<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="12">
        <div style="height:700px">
          <gmap-map
            ref="googlemap"
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 100%"
          >
            <!-- v-bind:options="mapStyle" -->
            <!-- <gmap-cluster> -->
            <gmap-marker
              :key="m.name"
              v-for="m in zones"
              :animation="2"
              :position="{ lat: Number(m.lat), lng: Number(m.lng) }"
              :clickable="true"
              @click="onZoneClick(m)"
            ></gmap-marker>
            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <v-card tile class="elevation-0 pa-0">
                <v-card-title class="pa-0">
                  <a
                    class="black--text pl-2"
                    @click="onZoneChange(selectedMarker.zid)"
                    >{{
                      selectedMarker.hasOwnProperty("name")
                        ? selectedMarker.name
                        : ""
                    }}</a
                  >
                </v-card-title>
              </v-card>
            </gmap-info-window>
          </gmap-map>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";

export default {
  name: "GoogleMapView",
  components: {},
  data() {
    return {
      zoom: 2,
      markers: [],
      selectedMarker: {},

      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      locInfoWinPos: {
        lat: 0,
        lng: 0,
      },
      locInfoWinOpen: true,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0,
        },
      },
      locInfoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      zones: "pinaStore/getterZones",
      selectedZone: "pinaStore/getterSelectedZone",
    }),
    center() {
      return this.zones.length > 0
        ? { lat: Number(this.zones[0].lat), lng: Number(this.zones[0].lng) }
        : { lat: 0, lng: 0 };
    },
  },
  watch: {
    selectedZone(newValue) {
      this.zoneSelected = newValue;
    },
  },
  methods: {
    onZoneChange(zid) {
      const zoneObj = this.$store.getters["pinaStore/getterZoneById"](zid);
      this.$store.commit("pinaStore/SET_SELECTED_ZONE", zoneObj);
      EventBus.$emit("zone-changed");
      this.infoWinOpen = false;
      this.$router.push({ path: "/zone/dashboard" });
    },
    onZoneClick(m) {
      this.selectedMarker = Object.assign({}, this.selectedMarker, m);
      this.infoWindowPos = {
        lat: Number(this.selectedMarker.lat),
        lng: Number(this.selectedMarker.lng),
      };
      this.infoWinOpen = !this.infoWinOpen;
    },
  },
};
</script>
